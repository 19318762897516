/* eslint-disable no-useless-escape */
import { Component, mixins } from 'nuxt-property-decorator';
import { MetaInfo } from 'vue-meta';
import { sectionId } from './index/-components/MyHotelSection';
import {
  HHotelGalleriesModal,
  HHotelGalleriesModalRef,
  HHotelToBooking,
  HPortalItem,
} from '~/components';
import NavigationHooksMixin, {
  NavigationHooksStack,
} from '~/mixins/navigation-hooks';
import {
  CrossSearchHotelMaster,
  HotelDetail,
  LanguageRedirectResult,
  NormalizedHotelFlowLineSettings,
  normalizeHotelFlowLineSettings,
} from '~/schemes';
import type HDefaultLayout from '~/layouts/default';
import { generateHreflang } from '~/helpers';

/** 構造化データの企業情報を定義 */
interface Organization {
  '@type': StructureTypes;
  name?: string;
  url?: string;
}

/** 構造化データ情報のjsonの定義 @link {https://schema.org/Hotel} */
interface StructureData {
  [key: string]: any;
  '@context': string;
  '@type': StructureTypes;
  name?: string;
  address?: string;
  logo?: string | null;
  url?: string;
  parentOrganization?: Organization;
}

/** 構造化データで使用されるタイプ */
export const STRUCTURE_TYPES = ['Hotel', 'Organization'] as const;

/** @typedef {('Hotel' | 'Organization')} StructureTypes */
export type StructureTypes = typeof STRUCTURE_TYPES[number];

@Component<HotelViewRoot>({
  scrollToTop: true,
  inject: ['layout'],
  provide() {
    return {
      hotelViewRoot: this,
    };
  },

  render() {
    return (
      <div staticClass="my-hotel-root-view" id={this.domId}>
        <nuxt-child />
        <HHotelGalleriesModal
          ref="galleriesModal"
          showcase
          galleries={this.hotel.galleries}
          galleryCategories={this.$commons.galleryCategories}
          v-model={this.galleryModalActive}
        />
        {!this.useLocalFooter && !this.hiddenFooter && (
          <HPortalItem to="portal-footer">
            <HHotelToBooking
              staticClass="my-hotel-root-view__portal-footer"
              hotel={this.hotel}
              eventId="fixedarea"
            />
          </HPortalItem>
        )}
      </div>
    );
  },

  /**  Nuxtのライフサイクル */
  async asyncData(ctx) {
    try {
      const hotelSource = await ctx.$api.hotels.getBySlug(
        ctx.params.hotel_slug,
      );

      if (!hotelSource) {
        ctx.error({ statusCode: 404 });
        return;
      }

      // 空室検索情報取得
      const crossSearch = await ctx.$api.crossSearch.all();

      /**
       * @todo
       * 有効言語リダイレクト
       * これは将来的にホテルページより上位の階層でも行うはずなので、
       * その時はここではなく、serverMiddlewareでやる
       */
      const currentLang = ctx.$language.current;
      const { availableLanguages } = hotelSource;
      const matched = availableLanguages.find((l) => l.id === currentLang);
      if (matched && matched.external && !ctx.$dataBucket.isPreview) {
        if (process.browser) {
          location.replace(matched.external);
        } else {
          /**
           * ここでヘッダ送信（end()）しちゃうとserver側のmiddlewareチェーンが例外吐くけど、
           * これしか現状良い場所がないので気にしない事にする
           * NuxtのFeatureに期待か別の仕組み検討
           */
          ctx.res.writeHead(302, { Location: matched.external });
          ctx.res.end();
        }
        return;
      }
      const redirectResult = ctx.$language.checkAndRedirect(
        availableLanguages.map((i) => i.id),
      );
      if (redirectResult === LanguageRedirectResult.Redirectd) {
        return;
      } else if (redirectResult === LanguageRedirectResult.Missing) {
        ctx.error({ statusCode: 404 });
        return;
      }

      if (!hotelSource.brand) {
        ctx.error({ statusCode: 404 });
        return;
      }

      await ctx.$hotel.setCurrent(hotelSource);

      // ブラウザ側でasyncDataフックが呼ばれた時は、この時点で宿GETS施設マスタをロードしておく
      // このコンポーネントのmountedフックでも同様にマスタのロードを行っているが、すでにロード済みの場合はキャンセルされるので問題ない
      if (process.browser) {
        await ctx.$hotel.loadCurrentHotelYgetsDetail();
      }

      return {
        hotelSource,
        crossSearch,
      };
    } catch (_err) {
      ctx.$error.throw(_err);
    }
  },

  head() {
    const head: MetaInfo = {};
    const { hotel, brand, hotelSource } = this;
    const lang = this.$language.current;
    let favicon: string | undefined;

    if (brand && brand.favicon) {
      favicon = brand.favicon;
    }

    if (hotel.favicon) {
      favicon = hotel.favicon;
    }

    const { og, ygetsId } = hotel;
    const globalFullNameAppends = hotel.globalFullName
      ? ` | ${hotel.globalFullName}`
      : '';
    const title = this.$t('value.hotelMetaTitle', {
      fullName: hotel.fullName,
      globalFullNameAppends,
    }) as string;
    const description = hotel.description;
    const ogImage = og.image;

    head.titleTemplate = (titleChunk) => {
      if (!titleChunk) {
        return title;
      }
      return `${titleChunk} | ${title}`;
    };

    head.meta = [
      {
        hid: 'description',
        name: 'description',
        content: description,
      },
      { hid: 'og:site_name', property: 'og:site_name', content: title },
      { hid: 'og:title', property: 'og:title', content: title },
      {
        hid: 'og:url',
        property: 'og:url',
        content: `${this.$navigation.origin}/${lang}/hotels/${hotel.slug}/`,
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: description,
      },
    ];

    if (ogImage) {
      head.meta.push({
        hid: 'og:image',
        property: 'og:image',
        content: this.$res.img(ogImage),
      });
    }

    head.link = generateHreflang(this, { hotelSource });

    if (favicon) {
      head.link.push({
        hid: 'favicon',
        rel: 'icon',
        type: 'image/x-icon',
        href: this.$res.img(favicon),
      });
    }

    const { style } = hotel;

    if (this.hotelHeaderIsActive) {
      head.htmlAttrs = {
        'data-hotel-header-active': '1',
      };
    }

    if (style) {
      head.style = [
        {
          cssText: style,
          type: 'text/css',
        },
      ];
    }

    head.script = [];
    head.noscript = [];

    /** @comment 今は界仙石原に絞って効果測定を実施しているが将来的にはもっと広く行う予定なので、その時にはscriptの管理方法を見直す必要がある */
    if (!this.$env.isDevelop && ygetsId === '0000000124') {
      /** 新見さんから依頼があり一時機能停止 */
      head.script.push({
        hid: 'tabi-raku__head-script',
        innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-WB2GMMS');`,
        type: 'text/javascript',
      });

      /** 新見さんから依頼があり一時機能停止 */
      // bodyの直下に埋め込み
      head.noscript.push({
        hid: 'tabi-raku__body-script', // このIDでサニタイズ無効化を指定
        innerHTML: `<iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-WB2GMMS"
            height="0"
            width="0"
            style="display:none;visibility:hidden"></iframe>`,
        pbody: true,
      });
      // サニタイズ無効化
      head.__dangerouslyDisableSanitizersByTagID = {
        'tabi-raku__body-script': ['innerHTML'],
      };
    }

    head.script.push(this.createStructureData);

    const { chatPlusSetting } = hotel;

    // チャットボットの設定なのでクライアントサイドで実行
    if (process.client) {
      if (chatPlusSetting) {
        head.script.push({
          id: `chat-plus`,
          innerHTML: this.extractScriptContent(chatPlusSetting),
          type: 'text/javascript',
        });
      }
    }

    return head;
  },
  mounted() {
    (window as any).setDebugPause = this.setDebugPause;

    // 宿GETS施設マスタはブラウザ側でマウントした後にロードする
    // 施設間を横断でSPA遷移した場合、このmountedフックはトリガーされないので、asyncData内で同様の処理をする
    this.$hotel.loadCurrentHotelYgetsDetail();

    const noscript = document.querySelector('tabi-raku__body_script');

    if (noscript) {
      noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-XXXXXX" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
      document.body.appendChild(noscript);
    }
  },
  beforeDestroy() {
    if (process.browser) {
      delete (window as any).setDebugPause;
    }
  },
})
export default class HotelViewRoot extends mixins<NavigationHooksMixin>(
  NavigationHooksMixin,
) {
  $refs!: {
    galleriesModal: HHotelGalleriesModalRef;
  };

  readonly layout!: HDefaultLayout;

  hotelSource: HotelDetail = null as any;
  crossSearch: CrossSearchHotelMaster = null as any;
  galleryModalActive: boolean = false;
  localNaviIsReached: boolean = false;

  /**
   * TOPページでスクロールアクティブなセクションID
   *
   * 'first-view'| 'feature'| 'guestroom' | 'default'
   */
  pageTopScrollActiveSectionId: sectionId = 'default';

  private debugPause: boolean = false;

  get brand() {
    return this.hotel.brand;
  }

  get logoIsScrollOuted() {
    if (!this.layout.isMounted) return false;
    return this.$window.scrollTop > 250;
  }

  get needHiddenHotelHeader() {
    return !this.logoIsScrollOuted;
  }

  get domId() {
    return `h-hotel--${this.hotel.slug}`;
  }

  get currentRouteName() {
    return this.$route.name;
  }

  get useLocalFooter() {
    return (
      this.currentRouteName ===
      'lang-hotels-hotel_slug-index-activities-activity_id'
    );
  }

  get hiddenFooter() {
    return (
      (this.isHotelTop && this.needHiddenHotelHeader) ||
      this.currentRouteName === 'lang-hotels-hotel_slug-index-roomsearch'
    );
  }

  get isHotelTop() {
    return this.currentRouteName === 'lang-hotels-hotel_slug-index';
  }

  get hotelHeaderIsActive() {
    return !this.isHotelTop || !this.needHiddenHotelHeader;
  }

  get needPauseAnimations() {
    return this.debugPause || this.galleryModalActive;
  }

  get hotel(): HotelDetail {
    return this.hotelSource;
  }

  get hasPhotoGroups() {
    return this.hotel.photoGroups.length > 0;
  }

  get hasActivities() {
    return !!this.hotel.activitySettings;
  }

  get hasRestaurantSettings() {
    return !!this.hotel.restaurantSettings;
  }

  get todo() {
    return this.hotel.todo;
  }

  filterSectionKey(key: string): boolean {
    const { hasActivities, hasRestaurantSettings, hasPhotoGroups, todo } = this;
    if (!hasActivities && key === 'activities') return false;
    if (!hasRestaurantSettings && key === 'dining') return false;
    if (!hasPhotoGroups && key === 'photogallery') return false;
    if (!todo && key === 'todo') return false;
    return true;
  }

  /**
   * 正規化済みの下層導線設定
   */
  get normalizedFlowLineSettings(): NormalizedHotelFlowLineSettings {
    return normalizeHotelFlowLineSettings(this.hotel.flowLineSettings, this);
  }

  /**
   * 下層導線設定を持っているか
   *
   * * 正規化済みのバナーが1件以上あるか
   */
  get hasFlowLineSettings() {
    return this.normalizedFlowLineSettings.banners.length > 0;
  }

  get navigationStack(): NavigationHooksStack {
    const directJumps = ['todo', 'activities', 'dining'];
    if (this.hotel.hasAccessContents) {
      directJumps.push('access');
    }
    const hotelName = this.hotel.name;

    return {
      /**
       * @MEMO
       *  当初、`"lang-hotels-hotel_slug-index"` のみを設定していたが、
       *  施設を横断するSPA遷移が発生した際に、ハンバーガーメニュー内のナビゲーションが更新されないという不具合が発生した。
       *  ここのIDは施設毎にユニークにしておかないと、コンポーネント側で変更検知できないので、URLスラッグを含めることにした。
       *
       *  ※しかし、本当はここの `local` というフィールドをgetter形式にしてやった方が良いとも思う。ここに依存している実装側でwatcher貼ってるのは保守観点上あまりよろしくない
       *
       * @see {@link https://hr-dev.backlog.jp/view/ACCO_CMS-1201}
       */
      id: `lang-hotels-hotel_slug-index:${this.hotel.slug}`,
      local: this.$theme.current.sections
        .filter(({ key }) => {
          if (key === 'feature' && !this.$hotel.hasFeature()) {
            return false;
          }

          if (key === 'todo' && !this.$hotel.hasTodo()) {
            return false;
          }

          if (key === 'activities' && !this.$hotel.hasActivity()) {
            return false;
          }

          if (
            key === 'dining' &&
            !this.$hotel.hasDining() &&
            !this.$theme.is('hoshinoya')
          ) {
            return false;
          }

          if (key === 'guestroom' && !this.$hotel.hasRoom()) {
            return false;
          }

          if (key === 'location' && !this.$hotel.hasLocation()) {
            return false;
          }

          return true;
        })
        .map(({ key, label, hiddenNavigation }) => {
          const toData = directJumps.includes(key)
            ? {
                path: key,
              }
            : {
                hash: key,
              };

          return {
            key,
            label: (label || '').replace('{{hotelName}}', hotelName),
            hidden: hiddenNavigation,
            to: this.$hotel.location(toData, this.hotel.slug),
          };
        })
        .filter((row) => {
          return this.filterSectionKey(row.key);
        }),
    };
  }

  /** 構造化データを構築 */
  get createStructureData() {
    const {
      fullName: name,
      address,
      slug,
      favicon: logo,
      checkIn,
      checkOut,
      numOfRooms: numberOfRooms,
      brand,
      pets,
      reservationCenter,
      description,
      introduction,
    } = this.hotel;
    /** ブランド名を取得 */
    const brandName = brand.name;
    /** ペットの可否情報を取得 */
    const petsAllowed = pets.type;
    /** 横断検索の情報を取得 */
    const crossSearchHotelData = this.crossSearch.hotelMasterList.find(
      (i) => i.hotelId === this.hotel.ygetsId,
    );
    /** 電話番号 */
    const telephone =
      reservationCenter && reservationCenter.jp && reservationCenter.jp.number;

    /** 基本設定>施設紹介>施設紹介画像を使用 */
    const image = introduction.image && introduction.image.derived.lg.url;

    /** geo情報を作成 */
    const geo = {
      lat:
        (crossSearchHotelData &&
          crossSearchHotelData.geolocation.lat.toString()) ||
        '',
      lng:
        (crossSearchHotelData &&
          crossSearchHotelData.geolocation.lng.toString()) ||
        '',
    };

    const lang = this.$language.current;
    const companyName = this.$i18n.t('name.hr') as string;
    const companyUrl = this.$i18n.t('url.globalTop') as string;
    const url = `${this.$navigation.origin}/${lang}/hotels/${slug}/`;

    const json: StructureData = {
      '@context': 'https://schema.org',
      '@type': 'Hotel',
      name,
      address,
      logo,
      url,
      parentOrganization: {
        '@type': 'Organization',
        name: companyName,
        url: companyUrl,
      },
      checkIn,
      checkOut,
      numberOfRooms,
      brand: brandName,
      petsAllowed,
      geo: {
        '@type': 'GeoCoordinates',
        latitude: geo.lat,
        longitude: geo.lng,
      },
      telephone,
      image,
      description,
    };

    return {
      hid: 'structured-data',
      type: 'application/ld+json',
      json,
    };
  }

  topSectionId() {
    if (this.navigationStack.local) {
      return this.navigationStack.local[0].key;
    }
    return '';
  }

  showGallery() {
    this.galleryModalActive = true;
  }

  onRequestGallery() {
    this.showGallery();
  }

  /**
   * @memo
   *   現在利用していない。けど、利用価値はあるので残しておく
   *   特定のカテゴリのカルーセルを表示できる
   */
  onRequestGalleryCategory(category: string) {
    this.$refs.galleriesModal.showCarousel(null, category, false);
  }

  onRequestGalleryId(id: string) {
    this.$refs.galleriesModal.showCarousel(id, null);
  }

  onActivateNavigationHooks() {
    this.$hotel.setCurrent(this.hotelSource);
  }

  onDectivateNavigationHooks() {
    this.$hotel.setCurrent(null);
  }

  private setDebugPause(pause: boolean) {
    this.debugPause = pause;
  }

  /** scriptタグの内容を抽出する処理 */
  private extractScriptContent(text: string): string {
    const scriptRegex = /<script\b[^>]*>([\s\S]*?)<\/script>/;
    const match = scriptRegex.exec(text);

    return match ? match[1].trim() : '';
  }
}
